import React from "react"

export default function footer() { 
    return(
    <>
    {/*<!--Footer-->*/}
    <footer class="skBrown py-10 shadow text-white">
    <div class="flex flex-wrap">
      <div class="flex-1 px-4 sm:w-1/2 md:w-1/4 mt-8 md:mt-0">
        <h5 class="text-xl text-white font-bold px-8 mb-6">Standorte</h5>
        <ul class="list-none footer-links">
          <li class="mb-2">
            <a href="/hofverkauf" class="border-b border-solid border-transparent text-white no-underline hover:border-white hover:text-white">Hofverkauf Wakenitzstraße 14</a>
          </li>
          <li class="mb-2">
            <a href="/schatzcafe" class="border-b border-solid border-transparent text-white no-underline hover:border-white hover:text-white">Schatz Cafe Wakenitzstraße 14</a>
          </li>
          <li class="mb-2">
            <a href="/ladenverkauf" class="border-b border-solid border-transparent text-white no-underline hover:border-white hover:text-white">Ladenverkauf Untertrave 104</a>
          </li>
        </ul>
      </div>
      <div class="flex-1 px-4 sm:w-1/2 md:w-1/4 mt-8 md:mt-0">
        <h5 class="text-xl text-white font-bold px-8 mb-6">Dienstleistungen</h5>
        <ul class="list-none footer-links">
          <li class="mb-2">
            <a href="/dienstleistungen" class="border-b border-solid border-transparent text-white no-underline hover:border-white hover:text-white">Haushaltsauflösungen</a>
          </li>
          <li class="mb-2">
            <a href="/dienstleistungen" class="border-b border-solid border-transparent text-white no-underline hover:border-white hover:text-white">Nachlassverwertungen</a>
          </li>
          <li class="mb-2">
            <a href="/dienstleistungen" class="border-b border-solid border-transparent text-white no-underline hover:border-white hover:text-white">Entrümpelungen</a>
          </li>
          <li class="mb-2">
            <a href="/dienstleistungen" class="border-b border-solid border-transparent text-white no-underline hover:border-white hover:text-white">Insolvenzverwertungen</a>
          </li>
        </ul>
      </div>
      <div class="flex-1 px-4 sm:w-1/2 md:w-1/4 mt-8 md:mt-0">
        <h5 class="text-xl text-white font-bold px-8 mb-6">An- & Verkauf</h5>
        <ul class="list-none footer-links">
          <li class="mb-2">
            <a href="/bilder" class="border-b border-solid border-transparent text-white no-underline hover:border-white hover:text-white">Bilder</a>
          </li>
          <li class="mb-2">
            <a href="/moebel" class="border-b border-solid border-transparent text-white no-underline hover:border-white hover:text-white">Möbel</a>
          </li>
          <li class="mb-2">
            <a href="/muenzen" class="border-b border-solid border-transparent text-white no-underline hover:border-white hover:text-white">Münzen</a>
          </li>
          <li class="mb-2">
            <a href="/produkte" class="border-b border-solid border-transparent text-white no-underline hover:border-white hover:text-white">Weitere</a>
          </li>
        </ul>
      </div>
      <div class="flex-1 px-4 sm:w-1/2 md:w-1/4 mt-8 md:mt-0">
        <h5 class="text-xl text-white font-bold px-8 mb-6">Kontakt</h5>
        <ul>
          <li class="mb-2">
            <a href="/kontakt" class="border-b border-solid border-transparent no-underline hover:border-white text-white hover:text-white">Mobil</a>
          </li>
          <li class="mb-2">
            <a href="/kontakt" class="border-b border-solid border-transparent no-underline hover:border-white text-white hover:text-white">Whatsapp</a>
          </li>
          <li class="mb-2">
            <a href="https://www.instagram.com/luebeck_schatzkammer/" class="border-b border-solid border-transparent no-underline hover:border-white text-white hover:text-white">Instagram</a>
          </li>
        </ul>
      </div>
    </div>
   
   
   
        
        <a class="text-white text-xs no-underline" href="https://www.stritago.de">© {new Date().getFullYear()}, Built by
        {` `} Stritago |</a>
        {` `}
        <a class="text-white text-xs no-underline" href="/impressum">Impressum |</a>
        {` `}
        <a class="text-white text-xs no-underline" href="/datenschutz">Datenschutz</a>
      

    </footer>
    </>
    )
}