import React, { useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import Dropdown from "./dropdown"


export default function Navbar(){ 

const [isOpen, setIsOpen] = useState(false)

return(
  <>
         <script type="text/javascript" data-cmp-ab="1" src="https://cdn.consentmanager.net/delivery/autoblocking/11bc70adafc5.js" data-cmp-host="a.delivery.consentmanager.net" data-cmp-cdn="cdn.consentmanager.net" data-cmp-codesrc="0"></script>
  {/*<!--Nav-->*/}    
    <nav id="header" class="sticky bg-white w-full l:w-5/5 z-30 top-0 shadow">
      <div class="w-full container mx-auto items-center justify-between mt-0 py-2">
        <div class="text-center items-center p-2">        
          <div class="font-serif toggleColour text-skBrown no-underline hover:no-underline text-2xl lg:text-4xl tracking-widest">
          SCHATZKAMMER LÜBECK
          </div>
          <p class="text-xl text-gray-400 tracking-widest">
            JAN BECKER
          </p>
        </div>  
      </div>
      
      
      <div class="skBrown text-white">
        <div class="block lg:hidden">
          <button onClick={() => setIsOpen(!isOpen)} id="nav-toggle" class="pl-4 text-white hover:text-white focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
            <FontAwesomeIcon icon={faBars} size="1x" />
          </button>
          <Dropdown open={isOpen}>
            <ul class="flex flex-col font-thin">
              <a class="text-white no-underline" href="/">STARTSEITE</a>
              <a class="text-white no-underline" href="/ladenverkauf">LADENVERKAUF</a>
              <a class="text-white no-underline" href="/hofverkauf">HOFVERKAUF</a>
              <a class="text-white no-underline" href="/schatzcafe">SCHATZCAFÉ</a>
              <a class="text-white no-underline" href="/haushaltsaufloesungen">HAUSHALTSAUFLÖSUNGEN</a>
              <a class="text-white no-underline" href="/an-und-verkauf">AN- UND VERKAUF</a>
              <a class="text-white no-underline" href="/kontakt">KONTAKT</a>
            </ul>
          </Dropdown>
        </div>
        <div class="block w-2/3 justify-center flex flex-grow lg:flex lg:w-auto hidden mt-2 lg:mt-0 skBrown  text-white lg:p-0 z-20">
          <ul class="text-xl font-thin space-x-6 pt-2">
              <a class="text-white no-underline" href="/">STARTSEITE</a>
              <a class="text-white no-underline" href="/ladenverkauf">LADENVERKAUF</a>
              <a class="text-white no-underline" href="/hofverkauf">HOFVERKAUF</a>
              <a class="text-white no-underline" href="/schatzcafe">SCHATZCAFÉ</a>
              <a class="text-white no-underline" href="/haushaltsaufloesungen">HAUSHALTSAUFLÖSUNGEN</a>
              <a class="text-white no-underline" href="/an-und-verkauf">AN- UND VERKAUF</a>
              <a class="text-white no-underline" href="/kontakt">KONTAKT</a>
          </ul>
        </div>
        
      </div>
      <hr class="border-b border-gray-100 opacity-25 my-0 py-0" />
    </nav>
  </>
)
}



