import React from "react"
import { Link } from "gatsby"

const PostLink = ({ post }) => (
  
  <article class="skBrown p-1 z-40 rounded-b">
<Link class="no-underline text-white" to={post.frontmatter.path}>
  <div class="flex flex-wrap -m-4">
        <div class="p-6">
        <img class="lg:h-60 xl:h-56 md:h-64 sm:h-72 xs:h-72 h-72 w-full object-cover object-center mb-2" src={post.frontmatter.thumbnail} alt={post.frontmatter.title} />
        <h2 class="text-lg text-white ">{post.frontmatter.title}</h2>
      </div>
  </div>
  </Link>
    

  </article>
  
)


export default PostLink